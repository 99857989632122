import React, { useState } from "react";
import Poster from "./Poster";
import PosterDetail from "./PosterDetail";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

const PostersPage = () => {
  const [selectedPoster, setSelectedPoster] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const posters = [
    {
      id: 1,
      imageThumbnailSrc: "./Thumbnail_Team_MUNZING_1000plus_poster.jpg",
      imageSrc: "./Team_MUNZING_1000plus_poster.jpg",
      title: "Team Munzing GmbH",
      description: "Description for Team Munzing poster.",
      companyDetails: "Details about Team Munzing.",
    },
    {
      id: 2,
      imageThumbnailSrc: "./Thumbnail_Team_STEINECKER_2_1000plus_poster.jpg",
      imageSrc: "./Team_STEINECKER_2_1000plus_poster.jpg",
      title: "Team Steinecker GmbH",
      description: "Description for Team Steinecker poster.",
      companyDetails: "Details about Team Steinecker.",
    },
    {
      id: 3,
      imageThumbnailSrc: "./Thumbnail_teamITQ_1000plus_poster.jpg",
      imageSrc: "./teamITQ_1000plus_poster.jpg",
      title: "Team ITQ GmbH",
      description: "Description for Team ITQ poster.",
      companyDetails: "Details about Team ITQ.",
    },

    // Add other posters similarly...
  ];

  const handlePosterClick = (poster) => {
    setSelectedPoster(poster);
  };

  const handleBackClick = () => {
    setSelectedPoster(null);
  };

  const posterStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fafafa",
  };

  const headerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "30px",
    marginBottom: "30px",
  };

  return (
    <div style={posterStyle}>
      {selectedPoster ? (
        <PosterDetail
          imageSrc={selectedPoster.imageSrc}
          title={selectedPoster.title}
          description={selectedPoster.description}
          companyDetails={selectedPoster.companyDetails}
          onNavigate={handleBackClick}
        />
      ) : (
        <div>
          <h1 style={headerStyle}>Poster Results</h1>
          <Swiper
            spaceBetween={1}
            autoplay={{ delay: 3000 }}
            slidesPerView={3} // Default to 1 slide per view for stacking
            breakpoints={{
              // When the window width is >= 768px, display 2 slides per view
              768: {
                slidesPerView: 2,
              },
              // When the window width is >= 1024px, display 3 slides per view
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {console.log("windowWidth", windowWidth)}
            {posters.map((poster) =>
              windowWidth < 820 ? (
                <div>
                  <SwiperSlide key={poster.id}>
                    <Poster
                      imageThumbnailSrc={poster.imageThumbnailSrc}
                      altText={poster.title}
                      onClick={() => handlePosterClick(poster)}
                    />
                  </SwiperSlide>
                </div>
              ) : (
                <SwiperSlide key={poster.id}>
                  <Poster
                    imageThumbnailSrc={poster.imageThumbnailSrc}
                    altText={poster.title}
                    onClick={() => handlePosterClick(poster)}
                  />
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default PostersPage;
