import React, { useState, useEffect } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import "./PhotoCarousel.css";

function PhotoCarousel({ images }) {
  const [perPage, setPerPage] = useState(calculatePerPage());

  // Function to calculate the number of slides per page based on screen size
  function calculatePerPage() {
    if (window.innerWidth < 768) {
      // Mobile devices
      return 1; // Smaller number of items for smaller screens
    } else if (window.innerWidth >= 768 && window.innerWidth < 1200) {
      // Tablets in portrait
      return 2;
    } else {
      // Larger desktops
      return 3;
    }
  }

  // Event listener to update perPage state on window resize
  useEffect(() => {
    function handleResize() {
      setPerPage(calculatePerPage());
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to ensure effect runs only once

  return (
    <Splide
      options={{
        perPage: perPage,
        rewind: true,
        gap: "1rem",
        autoplay: true,
      }}
    >
      {images.map((image) => (
        <SplideSlide key={image.src}>
          <div className="photos-container">
            <img
              src={image.src}
              alt={image.alt}
              className="rounded lazyload photos-style"
            />
          </div>
        </SplideSlide>
      ))}
    </Splide>
  );
}

export default PhotoCarousel;
