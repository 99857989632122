import React, { useEffect, useState } from "react";
import AntTable from "../../ui/table/Table";
import axios from "axios";
import { Button } from "antd";

function ViewSubscribers() {
  const [subscribers, setSubscribers] = useState([]);

  const ButtonStyle = {
    backgroundColor: "#64A0C8",
    color: "white",
    borderRadius: "4px",
  };

  const columns = [
    {
      dataField: "firstName",
      text: "First Name",
    },
    {
      dataField: "lastName",
      text: "Last Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "company",
      text: "Company",
    },
    {
      dataField: "position",
      text: "Position",
    },
  ];

  const exportSubscriberInformation = () => {
    axios({
      url: `/api/subscribers/downloadsubscribers`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Subscribers.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await axios.get("/api/subscribers");
        setSubscribers(response.data);
      } catch (error) {
        console.error("Error fetching subscribers:", error);
      }
    };

    fetchSubscribers();
  }, []);

  return (
    <div className="d-flex flex-column align-items-center p-5">
      <Button
        type="primary"
        style={ButtonStyle}
        onClick={exportSubscriberInformation}
      >
        Export Subscribers Information in Excel
      </Button>
      <AntTable data={subscribers} columns={columns} />
    </div>
  );
}

export default ViewSubscribers;
