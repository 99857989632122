// config.js
const config = {
  featureFlags: {
    showModalToStudents: true,
    showModalToStudentsAndCompanies: false,
    showStudentLoginButton: false,
  },
};

export default config;
