import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Typography,
  Modal,
} from "antd";
import { fieldofstudy } from "./studyCourses";
import { createProfile } from "../../../actions/profileActions";
import countryList from "../../countryselector/country-list";
import Button from "../../ui/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const CreateProfile = (props) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.user);
  const profileErrors = useSelector((state) => state.errors);

  const [formData, setFormData] = useState({
    firstname: authUser?.firstname || "",
    lastname: authUser?.lastname || "",
    matrikelnummer: authUser?.matrikelnummer || "",
    email: authUser?.email || "",
    placeofbirth: authUser?.placeofbirth || "",
    currentdegree: "",
    gender: "",
    germanlanguagelevel: "",
    countryofbirth: authUser?.countryofbirth || "",
    currentfieldofstudy: "",
    semester: "",
    motivation: "",
    willAttend: false,
    previousParticipation: "No",
    preferredFieldOfBusiness: [],
    errors: {},
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);

  const {
    firstname,
    lastname,
    matrikelnummer,
    email,
    placeofbirth,
    gender,
    countryofbirth,
    germanlanguagelevel,
    currentfieldofstudy,
    semester,
    motivation,
    currentdegree,
    willAttend,
    previousParticipation,
    preferredFieldOfBusiness,
    errors,
  } = formData;
  const countryOptions = countryList.getData();

  useEffect(() => {
    if (props.location.state === undefined) {
      props.history.push("/terms");
    }
    if (countryList.data[0].value !== "") {
      countryList.setEmpty("Select a Country").getLabel("");
    }
  }, [props.location.state, props.history]);

  useEffect(() => {
    if (profileErrors) {
      setFormData({ ...formData, errors: profileErrors });
    }
  }, [profileErrors]);

  const onSubmit = async (e) => {
    if (willAttend) {
      const profileData = {
        firstname,
        lastname,
        matrikelnummer,
        email,
        placeofbirth,
        gender,
        countryofbirth,
        germanlanguagelevel,
        currentfieldofstudy,
        semester,
        currentdegree,
        motivation,
        previousParticipation,
        preferredFieldOfBusiness,
        role: authUser.role,
      };

      try {
        const response = await axios.post("/api/profile", profileData);
        if (response.status === 200) {
          setShowSuccess(true);
        }
      } catch (error) {
        setShowError(true);
      }
    } else {
      setCheckboxError(true);
    }
  };

  const onChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Diverse", value: "Diverse" },
  ];

  const germanlevel = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "Mother Tongue", value: "Mother Tongue" },
  ];

  const semesterOptions = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
  ];

  const currentDegreeOptions = [{ label: "Masters", value: "Masters" }];

  const handleAttendChange = () => {
    setFormData({ ...formData, willAttend: !willAttend });
  };

  const handleBack = () => {
    props.history.push("/");
  };

  const [form] = Form.useForm();

  const [semesterError, setSemesterError] = useState("");
  const [consentDeclarationAgreed, setConsentDeclarationAgreed] =
    useState(false);

  return (
    <div className={"create-profile"}>
      <div className="row">
        <div className="col-md-8 m-auto">
          <h2
            style={{
              padding: "30px 12px",
              paddingBottom: "5px",
              fontWeight: 800,
            }}
          >
            ADD YOUR DETAILS
          </h2>
          <p
            style={{
              padding: "0px 12px",
              paddingBottom: "10px",
              fontWeight: 400,
            }}
          >
            * Please note that registration is limited to Master's students.
          </p>
          <Form onFinish={onSubmit} form={form}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="First Name"
                  labelCol={{ span: 24 }}
                  // name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "First Name is required",
                    },
                  ]}
                >
                  <Input
                    disabled
                    placeholder="First Name"
                    onChange={(e) => onChange("firstname", e.target.value)}
                    value={firstname}
                    name="firstname"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="Last Name"
                  labelCol={{ span: 24 }}
                  // name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Last Name is required",
                    },
                  ]}
                >
                  <Input
                    disabled
                    placeholder="Last Name"
                    onChange={(e) => onChange("lastname", e.target.value)}
                    value={lastname}
                    name="lastname"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="Matriculation Number"
                  labelCol={{ span: 24 }}
                  // name="matrikelnummer"
                  rules={[
                    {
                      required: true,
                      message: "Matriculation Number is required",
                    },
                  ]}
                >
                  <Input
                    disabled
                    placeholder="Matriculation Number"
                    onChange={(e) => onChange("matrikelnummer", e.target.value)}
                    value={matrikelnummer}
                    name="matrikelnummer"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="Email"
                  labelCol={{ span: 24 }}
                  // name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email is required",
                    },
                  ]}
                >
                  <Input
                    disabled
                    placeholder="Email"
                    onChange={(e) => onChange("email", e.target.value)}
                    value={email}
                    name="email"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="Place of Birth"
                  labelCol={{ span: 24 }}
                  // name="placeofbirth"
                  rules={[
                    {
                      required: true,
                      message: "Place of Birth is required",
                    },
                  ]}
                >
                  <Input
                    disabled
                    placeholder="Place of Birth"
                    onChange={(value) => onChange("placeofbirth", value)}
                    value={placeofbirth}
                    name="placeofbirth"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="Country of Birth"
                  labelCol={{ span: 24 }}
                  // name="countryofbirth"
                  rules={[
                    {
                      required: true,
                      message: "Country of Birth is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Country of Birth"
                    onChange={(value) => onChange("countryofbirth", value)}
                    value={countryofbirth}
                    disabled
                  >
                    {countryOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="Current Field of Study"
                  name="currentfieldofstudy"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Current Field of Study is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Current Field of Study"
                    onChange={(value) => onChange("currentfieldofstudy", value)}
                    value={currentfieldofstudy}
                  >
                    {fieldofstudy.map((field) => (
                      <Option key={field.value} value={field.value}>
                        {field.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="Gender"
                  name="gender"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Gender is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Gender"
                    onChange={(value) => onChange("gender", value)}
                    value={gender}
                  >
                    {genderOptions.map((gender) => (
                      <Option key={gender.value} value={gender.value}>
                        {gender.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="Current Degree"
                  name="degree"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Current degree is required",
                    },
                    // (formInstance) => ({
                    //   message:
                    //     "If you are a bachelors student, you can only choose semester 5 and 6!",
                    //   validator(rule, value) {
                    //     if (value === null) {
                    //       return Promise.resolve();
                    //     }
                    //     const semester = formInstance.getFieldValue("semester");
                    //     if (
                    //       value == "Bachelors" &&
                    //       (semester == 1 ||
                    //         semester == 2 ||
                    //         semester == 3 ||
                    //         semester == 4)
                    //     ) {
                    //       return Promise.reject(new Error());
                    //     }
                    //     return Promise.resolve();
                    //   },
                    // }),
                  ]}
                  // validateTrigger="onSubmit"
                >
                  <Select
                    placeholder="Current Degree"
                    onChange={(value) => onChange("currentdegree", value)}
                    value={currentdegree}
                    name="degree"
                  >
                    {currentDegreeOptions.map((field) => (
                      <Option key={field.value} value={field.value}>
                        {field.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="Current Semester"
                  name="semester"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Current Semeseter is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Semester"
                    onChange={(value) => onChange("semester", value)}
                    value={semester}
                    name="semester"
                  >
                    {semesterOptions.map((field) => (
                      <Option key={field.value} value={field.value}>
                        {field.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="Have you participated in the previous 1.000+ Project Week?"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message:
                        "Please select if you have participated in the previous 1.000+ Project Week",
                    },
                  ]}
                >
                  <Select
                    placeholder="Previous Participation in 1.000+"
                    onChange={(value) =>
                      onChange("previousParticipation", value)
                    }
                    value={previousParticipation}
                    name="previousParticipation"
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="Preferred Fields of Business (Max 3)"
                  labelCol={{ span: 24 }}
                  name="preferredFieldOfBusiness"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the preferred field of business",
                    },
                    () => ({
                      validator(_, value) {
                        if (value && value.length > 3) {
                          return Promise.reject(
                            new Error(
                              "Please select only up to 3 fields of study."
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select
                    mode="multiple"
                    maxTagCount={3}
                    placeholder="Preferred Field of Business"
                    onChange={(value) =>
                      onChange("preferredFieldOfBusiness", value)
                    }
                    value={preferredFieldOfBusiness}
                    name="preferredFieldOfBusiness"
                  >
                    <Option value="Mobility">Mobility</Option>
                    <Option value="IT">IT</Option>
                    <Option value="Security">Security</Option>
                    <Option value="Digitization">Digitization</Option>
                    <Option value="Material & Production">
                      Material & Production
                    </Option>
                    <Option value="Energy & Construction">
                      Energy & Construction
                    </Option>
                    <Option value="Health">Health</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="German Language Level"
                  name="germanlanguagelevel"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "German Language Level of Study is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="German Language Level"
                    onChange={(value) => onChange("germanlanguagelevel", value)}
                    value={germanlanguagelevel}
                  >
                    {germanlevel.map((field) => (
                      <Option key={field.value} value={field.value}>
                        {field.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={24} style={{ padding: "0px 20px" }}>
                <Form.Item
                  label="Motivation"
                  name="motivation"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Motivation is required",
                    },
                  ]}
                >
                  <TextArea
                    showCount
                    maxLength={1000}
                    style={{
                      height: 120,
                      marginBottom: 16,
                    }}
                    onChange={(e) => onChange("motivation", e.target.value)}
                    placeholder="Motivation"
                    name="semester"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} style={{ padding: "0px 20px" }}>
                <Form.Item
                  name="consentDeclarationAgreed"
                  rules={[
                    () => ({
                      message: "Please agree to the Declaration of Consent",
                      validator(rule, value) {
                        if (consentDeclarationAgreed === false) {
                          return Promise.reject(new Error());
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Checkbox
                    name="consentDeclarationAgreed"
                    checked={consentDeclarationAgreed}
                    onChange={() =>
                      setConsentDeclarationAgreed(!consentDeclarationAgreed)
                    }
                  >
                    I agree with the{" "}
                    <a
                      href="/consent/Declaration_of_Consent_Students.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Declaration of Consent
                    </a>
                    .
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} md={24} style={{ padding: "0px 20px" }}>
                <Checkbox
                  id="attend"
                  name="attend"
                  value="willAttend"
                  onChange={handleAttendChange}
                >
                  <Text strong>
                    Yes, I will attend from January 13th - 17th at 1.000+
                    project week on site.
                  </Text>
                </Checkbox>
                {checkboxError ? (
                  <p style={{ color: "red" }}>
                    * Kindly acknowledge the message provided above.
                  </p>
                ) : null}
                {semesterError !== "" ? (
                  <p style={{ color: "red" }}>* {semesterError}</p>
                ) : null}
              </Col>
              <Col xs={24} md={24} style={{ padding: "0px 20px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "10px 0px" }}>
                    <Button
                      text="SUBMIT"
                      variant={"primary"}
                      htmlType="submit"
                    />
                  </div>
                  <div style={{ padding: "10px 0px" }}>
                    <Button
                      text="CANCEL SIGNING UP"
                      variant={"secondary"}
                      style={{
                        color: "white",
                        backgroundColor: "grey",
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                      onClick={() => props.history.push("/")}
                    />
                  </div>
                </div>
              </Col>
              <Modal
                title={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px 0px",
                    }}
                  >
                    <h2 style={{ color: "#DB6C1F" }}>CONGRATULATIONS</h2>
                  </div>
                }
                open={showSuccess}
                closable={false}
                keyboard={false}
                footer={[
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      padding: "10px 0px",
                    }}
                  >
                    <Button
                      text="GO BACK TO HOMEPAGE"
                      onClick={handleBack}
                      variant={"secondary"}
                      style={{ color: "white" }}
                    />
                  </div>,
                ]}
              >
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  You have successfully registered with us for the project week
                  1.000+. Please check your email for further instructions.
                </p>
              </Modal>
              <Modal
                title={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      padding: "20px 0px",
                    }}
                  >
                    <h2 style={{ color: "#FF0000" }}>
                      ERROR OCCURED WHILE SIGNING UP
                    </h2>
                  </div>
                }
                open={showError}
                closable={false}
                keyboard={false}
                footer={[
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      padding: "10px 0px",
                    }}
                  >
                    <Button
                      text="GO BACK TO HOMEPAGE"
                      onClick={handleBack}
                      variant={"secondary"}
                      style={{ color: "white" }}
                    />
                  </div>,
                ]}
              >
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  Please try again after a few minutes. If the issue presists,
                  contact us on our email 1000plus@cit.tum.de.
                </p>
              </Modal>
            </Row>
          </Form>
          <br />
        </div>
      </div>
    </div>
  );
};

CreateProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  createProfile: PropTypes.func.isRequired,
};

export default withRouter(CreateProfile);
