import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

// Import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const newsItems = [
  // {
  //   image: "/news/Electronica.jpg",
  //   title: "Electronica",
  //   description:
  //     "Young Talents Day Leading the way to your future career. Join the student's day on 15 November at Electronica.",
  //   link: "https://electronica.de/en/trade-fair/program/careers/young-talents/",
  //   expirationDate: "2024-11-16",
  // },
  {
    image: "/news/WomenOfTum.png",
    title:
      "Grit, Grind & Greatness – Women of TUM across Industry, Innovation & Science",
    description:
      "The Women of TUM Talk 2025 brings inspiring TUM alumnae to the stage to share their career journeys and insights. Take this opportunity to connect, network, and gain new perspectives for your own path! \n 9 April, 2025.",
    link: "https://www.community.tum.de/veranstaltung/5097/",
    expirationDate: "2025-04-09",
  },
  {
    image: "/news/TUMLC.png",
    title: "TUM Language Center",
    description:
      "Check out TUM Language Center. Develop your communication skills in a foreign language! \n 28 February, 2025.",
    link: "https://www.sprachenzentrum.tum.de/en/sz/homepage/",
    expirationDate: "2025-02-28",
  },
  {
    image: "/news/TUMFreising.png",
    title: "Science Explained for ALL - TUM@Freising Lecture Series",
    description:
      '"Meat from the bioreactor - is that the future?" at Lindenkeller Freising. \n 6 March, 2025 from 7:00 - 8:30 p.m.',
    link: "https://www.ls.tum.de/en/ls/public-relations/events/event-detail/article/tum-at-freising-lecture/",
    expirationDate: "2025-03-06",
  },
  {
    image: "/news/EngineersforGerm.png",
    title: "Engineers for Germany",
    description:
      "Apply now to secure your spot in the Engineers for Germany program! Deadline: \n 14 March, 2025.",
    link: "https://www.community.tum.de/en/engineers-for-germany/",
    expirationDate: "2025-03-14",
  },
  {
    image: "/news/IKOMLifeScience.png",
    title: "IKOM Life Science",
    description:
      "Don't miss IKOM Life Science! at TUM Freising. \n 13 May, 2025",
    link: "https://ikom-tum.de/life-science/",
    expirationDate: "2025-05-13",
  },
  {
    image: "/news/IKOM.png",
    title: "IKOM",
    description: "Don't miss IKOM! at TUM Campus Garching. \n 23-26 May, 2025",
    link: "https://ikom-tum.de/",
    expirationDate: "2025-06-26",
  },
  {
    image: "/news/TUMOpenCampus.jpg",
    title: "TUM Open Campus",
    description:
      "TUM Open Campus: A taste of university life for prospective students. \n March, 2025.",
    link: "https://youtu.be/6iQUggHzp18",
    expirationDate: "2025-03-31",
  },
  {
    image: "/news/HealthTech.png",
    title: "Healthtech Innovation Insight",
    description:
      "Opportunities and risks of artificial intelligence: focus on patient safety. \n 26 February, 2025.",
    link: "https://www.bayern-innovativ.de/en/events-dates/health/detail/healthtech-innovation-insight-opportunities-and-risks-of-artificial-intelligence-focus-on-patient-safety/",
    expirationDate: "2025-02-26",
  },
  {
    image: "/news/InterviewClinics.png",
    title: "Interview Clinics",
    description:
      "A mock interview can help immensely with confidence when you are preparing for the real deal. Want to polish your interview skills? Come and participate! \n 5 March, 2025.",
    link: "https://www.community.tum.de/en/event/5002/",
    expirationDate: "2025-03-05",
  },
];

const News = () => {
  // Install Swiper modules
  SwiperCore.use([Pagination, Navigation, Autoplay]);

  return (
    <div
      id="news"
      style={{
        margin: "0 auto",
        position: "relative",
        marginTop: "25px",
        backgroundColor: "#fafafa",
        // border: "30px solid #64A0C8",
        // borderRadius: "10px",
      }}
    >
      {/* check if the newsItems is empty remove the header */}
      {newsItems.length === 0 ? null : (
        <h1
          style={{ textAlign: "center", marginTop: "10px", fontSize: "30px" }}
        >
          Upcoming Events
        </h1>
      )}

      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation={false}
        loop={true}
        autoplay={{ delay: 16000 }}
        style={{
          paddingBottom: "40px", // Move the dots down a bit
        }}
      >
        {newsItems.map((slide, index) =>
          //check on expiration date if passed compared to today's date then don't show the slide
          new Date(slide.expirationDate) < new Date() ? null : (
            <SwiperSlide key={index}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center", // Center content horizontally
                  justifyContent: "center", // Center content vertically
                  textAlign: "center", // Center text
                  height: "100%", // Fill the container height
                }}
              >
                {/* Image Section */}

                <img
                  src={slide.image}
                  alt={slide.title}
                  style={{
                    width: "40%", // Adjust width for better responsiveness
                    height: "80%",
                    borderRadius: "10px",
                    marginBottom: "20px", // Add some space between the image and text
                    marginTop: "20px",
                  }}
                />

                {/* Title */}
                <h3
                  style={{
                    width: "45%",
                    fontSize: "18.5px",
                    marginBottom: "10px",
                    fontWeight: "600",
                  }}
                >
                  {slide.title}
                </h3>

                {/* Description */}
                <p
                  style={{
                    width: "40%",
                    fontSize: "15.5px",
                    marginBottom: "10px",
                  }}
                >
                  {slide.description.split("\n").map((text, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {text}
                    </React.Fragment>
                  ))}
                </p>

                <a href={slide.link}>
                  <button
                    style={{
                      width: "150px",
                      height: "40px",
                      borderRadius: "25px",
                      backgroundColor: "#DB6C1F",
                      color: "white",
                    }}
                  >
                    Read more
                    <FontAwesomeIcon
                      icon={faArrowRight} // Correct usage of icon object
                      style={{ marginLeft: "7px", marginTop: "5px" }} // Adding margin between text and icon
                    />
                  </button>
                </a>
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper>

      {/* Custom styles for navigation buttons */}
      <style>
        {`
          .swiper-button-next, .swiper-button-prev {
            color: #000; /* Customize arrow color */
            width: 10px;
            height: 10px;
            top: 350px; 
          }

          .swiper-button-next {
            right: 250px; /* Move right arrow out of the slide */
          }

          .swiper-button-prev {
            left: 250px; /* Move left arrow out of the slide */
          }

          @media (max-width: 768px) {
            .swiper-button-next{
              right: 20px; /* Adjust for smaller screens */
              top: 150px;
            }
            .swiper-button-prev {
              left: 20px; /* Adjust for smaller screens */
              top: 150px;
            }
            
          }
        `}
      </style>
    </div>
  );
};

export default News;
